import { Component, OnInit, AfterViewInit, AfterViewChecked, AfterContentInit } from '@angular/core';
import { AuthenticationService } from '../services/index';
import { Router } from '@angular/router';
import { CookieService } from 'ngx-cookie';
import * as moment from 'moment';
declare var $: any;
// Metadata
export interface RouteInfo {
    path: string;
    title: string;
    type: string;
    icontype: string;
    // icon: string;
    children?: ChildrenItems[];
    role?: string[];
    large_title?: string;
}

export interface ChildrenItems {
    path: string;
    title: string;
}

// Menu Items
export const ROUTES: RouteInfo[] = [{
    path: '/dashboard',
    title: 'Admin Overview',
    type: 'link',
    icontype: 'ti-panel',
    children: [
        {
            path: 'timesheet',
            title: 'Time Sheet'
        },
        {
            path: 'timesheet2',
            title: 'Time Sheet'
        },
        {
            path: 'pto',
            title: 'PTO Request'
        },
        {
            path: 'sick',
            title: 'Sick Day Request'
        },
        {
            path: 'expenseporeport',
            title: 'Expense / Purchase Order Report'
        },
        {
            path: 'admin',
            title: 'Time Sheet/PTO Admin'
        },
        {
            path: 'expensepoadmin',
            title: 'Expense / Purchase Order Report Admin'
        },
        {
            path: 'teamoverview',
            title: 'Team Overview'
        },
        {
            path: 'commcalendar',
            title: 'Communication Calendar'
        },
        {
            path: 'askada',
            title: 'Ask Ada'
        },
        {
            path: 'linkedinhandler',
            title: 'LinkedIn Init Handler'
        },
        {
            path: 'employeedir',
            title: 'Employee Directory'
        }
    ]
}, {
    path: '/serviceman',
    title: 'Client 360°',
    type: 'link',
    icontype: 'ti-user',
    children: [
        {
            path: 'users',
            title: 'User Management'
        },
        {
            path: 'company',
            title: 'Company Management',
        },
        {
            path: 'reports',
            title: 'Report Management',
        },
        {
            path: 'assigncompany',
            title: 'Assign Company',
        },
        {
            path: 'activeusers',
            title: 'Active Users'
        },
        {
            path: 'globalreports',
            title: 'Global Reports'
        },
        {
            path: 'alertmodules',
            title: 'Teams Alert Modules'
        },
        {
            path: 'mapreportscheduler',
            title: 'Override Mapped Report Scheduler'
        },
        {
            path: 'fullmapview',
            title: 'Full Map View'
        },
        {
            path: 'advanceusersearch',
            title: 'Advanced User Search'
        }
    ],
    role: ['Serviceman'],
}, {
    path: '/qamanager',
    title: 'QA Manager',
    large_title: 'Quality Assurance Manager',
    type: 'link',
    icontype: 'ti-pencil-alt',
    role: ['Infoman'],
    children: [
        {
            path: 'blockchecker',
            title: 'Block Checker',
        },
        {
            path: 'spotchecker',
            title: 'Spot Checker',
        }
    ]
}, {
    path: '/metaman',
    title: 'Meta Manager',
    type: 'link',
    icontype: 'ti-apple',
    role: ['MetaManager'],
    children: [
        {
            path: 'botmodules',
            title: 'Bot Modules',
        },
        {
            path: 'combinator',
            title: 'Combinator',
        },
        {
            path: 'newsedgesim',
            title: 'NewsEdge Simulation',
        },
        {
            path: 'mapainotes',
            title: 'Map AI Notes',
        }
    ]
}, {
    path: '/biaggregator',
    title: 'BI Aggregator',
    type: 'link',
    icontype: 'ti-briefcase',
    role: ['MetaManager~FullAccess']
}, {
    path: '/dataman',
    title: 'Data Manager',
    type: 'link',
    icontype: 'ti-stats-up',
    role: ['DataManager', 'Outsourced'],
},
{
    path: '/infoman',
    title: 'Information Manager',
    type: 'link',
    icontype: 'ti-info-alt',
    role: ['Infoman', 'Outsourced'],
    children: [
        {
            path: 'addarticle',
            title: 'Add Article'
        },
        {
            path: 'editarticle',
            title: 'Edit Article',
        },
        {
            path: 'previewarticle',
            title: 'Preview Article'
        },
        {
            path: 'search',
            title: 'Information Manager Search',
        },
        {
            path: 'newsedgerss',
            title: 'NewsEdge RSS Feeds',
        },
        {
            path: 'rssfeedsoverview',
            title: 'RSS Feed Overview',
        },
        {
            path: 'marketcms',
            title: 'Our Markets CMS',
        },
        {
            path: 'industryreports',
            title: 'Industry Report(s)',
        },
        {
            path: 'autopublish',
            title: 'Review LinkedIn/Legislation/Regulation AutoPublish',
        },
        {
            path: 'addcontentmarket',
            title: 'Add Content Marketing',
        },
        {
            path: 'editcontentmarket',
            title: 'Edit Content Marketing',
        },
        {
            path: 'eventmanage',
            title: 'Event Management',
        },
        {
            path: 'metrics',
            title: 'Content Metrics',
        },
        {
            path: 'gatekeeper',
            title: 'BI Aggregator - Gate Keeper'
        },
        {
            path: 'gatereviewer',
            title: 'BI Aggregator - Gate Reviewer'
        },
        {
            path: 'duplicatelogs',
            title: 'BI Aggregator - Duplicate Logs'
        },
        {
            path: 'editoraitags',
            title: 'BI Aggregator - Editor/AI Tags'
        }
    ]
}, {
    path: '/pagemonitor',
    title: 'Page Monitor',
    type: 'link',
    icontype: 'ti-pulse',
    role: ['Infoman']
}
];

@Component({

    selector: 'sidebar-cmp',
    templateUrl: 'sidebar.component.html',
    styleUrls: ['sidebar.component.css']
})

export class SidebarComponent implements OnInit, AfterViewInit {
    public menuItems: any[];
    public showInternalUsers = false;
    public userInfo;
    constructor(private authenticationService: AuthenticationService, private router: Router, private cookieSvc: CookieService) { }
    isNotMobileMenu() {
        if ($(window).width() > 991) {
            return false;
        }
        return true;
    }

    ngOnInit() {
        this.updateAvatar();
        let isWindows = navigator.platform.indexOf('Win') > -1 ? true : false;
        this.menuItems = ROUTES.filter((menuItem) => {
            if(menuItem.hasOwnProperty('role')){
                if(this.authenticationService.hasRole(menuItem.role)){
                    return menuItem;
                }
            }else{
                return menuItem;
            }
        });
        // HIDE ROUTES USED FOR TESTING -- IT ACCESS ONLY
        // if (!this.authenticationService.isITAccess() && !this.authenticationService.isSMSuperAdminAccess()) {
        //     this.menuItems = this.menuItems.filter(mItem => {
        //         return mItem.path != '/infoman';
        //     })
        // }
        isWindows = navigator.platform.indexOf('Win') > -1 ? true : false;

        if (isWindows) {
            // if we are on windows OS we activate the perfectScrollbar function
            $('.sidebar .sidebar-wrapper, .main-panel').perfectScrollbar();
            $('html').addClass('perfect-scrollbar-on');
        } else {
            $('html').addClass('perfect-scrollbar-off');
        }
    }
    async updateAvatar() {
        let UserInfoSession = await JSON.parse(localStorage.getItem('AdminUserInfo'));
        if (UserInfoSession) {
            this.userInfo = UserInfoSession.Info.qUser[0];
            if (UserInfoSession === null) {
                const LoginTokenVal = await this.cookieSvc.get('AdminLoginToken');
                if (LoginTokenVal !== null) {
                    this.authenticationService.checkUser(LoginTokenVal).then((returnResults: any) => {
                        if (returnResults.authorized === true) {
                            const expiryDate = moment().local().add(600, 'minutes');
                            localStorage.setItem('AdminUserInfo', JSON.stringify({ token: LoginTokenVal, Info: returnResults.cert }));
                            localStorage.setItem('AdminUserInfoExpiry', JSON.stringify({ expiryDate }));
                            UserInfoSession = JSON.parse(localStorage.getItem('AdminUserInfo'));
                            this.userInfo = UserInfoSession.Info.qUser[0];
                        } else if (returnResults.authorized === false) {
                            this.authenticationService.logout()
                        }
                    });
                };
            };
        }
    }
    logout() {
        this.router.navigate(['pages/login']);
        this.authenticationService.logout();
    }
    routeSubmenu(externalPath: boolean, path: Array<any>, externalLink?: string) {
        if (externalPath) {
            window.open(externalLink, '_blank');
        } else {
            this.router.navigate(path);
        }
    }
    ngAfterViewInit() {
        const $sidebarParent = $('.sidebar .nav > li.active .collapse li.active > a').parent().parent().parent();

        const collapseId = $sidebarParent.siblings('a').attr('href');

        $(collapseId).collapse('show');
    }
}
